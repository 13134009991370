import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="April 2022" />
		<NewsHeader />
		<h2>April 2022</h2>

		<h3 className="blogdate">
			Friday, April 7, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="2">Wonderful Words Spotify editorial playlist</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Great news!
				On Tuesday, Tico's and my new single, <span className="italic">Wonderful Words</span>, was added to the Vocal Jazz editorial playlist over at Spotify!
				We deeply appreciate the listens and shares that got it there so quickly, and we hope you will keep them comin'!
			</p>
			<p>
				<a href="https://open.spotify.com/playlist/37i9dQZF1DX2kt7dB63bU1?prid=spotify:track:53GtzwyXwm4ucKVJ5KZu4X">Check it out</a>
			</p>
			<p>
				🎶 <span className="italic">Words were lost, yet some engraved... some words may have misbehaved!</span> 🎶
			</p>
		</div>

		<h3 className="blogdate">
			Friday, April 1, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">New Release: Wonderful Words</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Tico de Moraes and I got together again, writing and producing a new song.
				We're pretty excited about it, and now it's time to share <span className="italic">Wonderful Words</span> with you.
				You might remember we released a song last year together called <span className="italic">Fettering Chance</span>;
				today we're offering one a little bit more upbeat—a jazz/pop number.
			</p>
			<p>
				🎶 <span className="italic">Words were lost, yet some engraved... some words may have misbehaved!</span> 🎶
			</p>
			<p>
				Please find it wherever you prefer to stream or buy digital music.
				You might do that while also reading more about it at my <Link to="/collab/">collabs page</Link>.
			</p>
			<br />
			<div className="main--centerwrapper">
			<StaticImage
				src="../../images/collab artwork/JRCOLLAB05 wonderful words.jpg"
				alt="Tico de Moraes, Jesse Rivest - Wonderful Words - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={300}
			/></div>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
